.SignUp {
	margin-bottom: 1rem;
}

.SignIn {
	margin-top: 1rem;
	color: var(--base-color-medium);
}

.SignIn > a {
	text-decoration: underline;
	color: var(--base-color-medium);
}

.PhoneFormField {
	margin: 0;
	width: 100%;
}
