.ProgressBar {
	height: 0.5rem;
	background-color: var(--primary-color-dark);
	transition: 0.3s all linear;
	animation: progress 3s ease-in-out forwards;
}

.Steps {
	display: flex;
	flex-direction: row;
}

.Step {
	height: 1rem;
	width: 1rem;
	background-color: var(--primary-color-dark);
	border-radius: 50%;
}
