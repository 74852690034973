.Page {
	height: 100%;
	min-height: 100vh;
	overflow: auto;
	display: grid;
	grid-template-columns: 1fr;
}

@media (min-width: 900px) {
	.Page {
		grid-template-columns: minmax(29rem, 40rem) minmax(30rem, 1fr);
	}
}

.Content {
	padding: 3.5rem 4.5rem;
}

@media (max-width: 900px) {
	.Content {
		min-height: calc(100vh - 14rem);
	}
}

.TopBar {
	margin-bottom: 1rem;
}

.Logo {
	height: 3rem;
	width: 3rem;
	padding: 0.8125rem 1.3125rem 0.75rem 1.3125rem;
}

.BackButton {
	padding: 0 4.5rem;
}

.Sidebar {
	position: relative;
	padding: 2.5rem;
	border-radius: 2rem;
	min-height: 40rem;
	margin: 2rem;
	background: rgb(28 32 71);
	display: grid;
	grid-template-columns: 300px 1fr;
	justify-items: center;
}

.Blurs {
	border-radius: 2rem;
	overflow: hidden;
	width: 100%;
	height: 100%;
	position: absolute;
	left: 0;
	top: 0;
	pointer-events: none;
}

.Blur {
	filter: blur(250px);
	background-image: linear-gradient(
		to right top,
		var(--color-purple-4),
		rgb(28 32 71 / 0%)
	);
	width: 300px;
	height: 200px;
	position: absolute;
	top: 20px;
	left: 40px;
}

.Blur2 {
	filter: blur(150px);
	background-image: linear-gradient(
		to right top,
		var(--color-purple-8),
		rgb(28 32 71 / 0%)
	);
	width: 600px;
	height: 333px;
	position: absolute;
	bottom: -120px;
	right: -120px;
}

.Blur3 {
	filter: blur(150px);
	background-image: linear-gradient(
		to right top,
		var(--color-pink),
		rgb(28 32 71 / 0%)
	);
	width: 200px;
	height: 200px;
	position: absolute;
	top: 60px;
	right: 120px;
}

.Phone {
	position: absolute;
	top: calc(50% - 300px);
	left: -2rem;
	transform: perspective(1500px) rotateY(-4deg) rotateX(5deg);
	box-shadow: rgb(0 0 0 / 25%) 0 25px 50px -12px;
	transition: transform 1s ease 0s;
}

.Phone:hover {
	transform: perspective(3000px) rotateY(7deg) rotateX(-5deg);
}

.Hero {
	grid-column: 2;
	margin: 1rem;
}

@media (max-width: 1300px) {
	.Phone {
		position: revert;
		grid-column: 1 / 3;
	}

	.Hero {
		grid-column: 1 / 3;
	}

	.HeroTitle {
		font-size: 2rem;
		line-height: 2.5rem;
	}

	.HeroSubtitle {
		font-size: 1.75rem;
	}
}
