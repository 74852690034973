.Phone {
	border-radius: 2.5rem;
	width: 300px;
	height: 600px;
	background: var(--color-grey-12);
	display: flex;
	padding: 1rem;
}

.VolumeUp {
	position: absolute;
	width: 5px;
	height: 40px;
	top: 160px;
	left: -4px;
	background: var(--color-grey-12);
	border-end-start-radius: 0.5rem;
	border-start-start-radius: 0.5rem;
}

.VolumeDown {
	position: absolute;
	width: 5px;
	height: 40px;
	top: 220px;
	left: -4px;
	background: var(--color-grey-12);
	border-end-start-radius: 0.5rem;
	border-start-start-radius: 0.5rem;
}

.Power {
	position: absolute;
	width: 5px;
	height: 60px;
	top: 180px;
	right: -4px;
	background: var(--color-grey-12);
	border-end-end-radius: 0.5rem;
	border-start-end-radius: 0.5rem;
}

.Screen {
	border-radius: 2rem;
	background: var(--color-grey-9);
	height: auto;
	flex: 1;
	padding: 2rem 1rem;
}
