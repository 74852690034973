.PhoneMessage {
	position: relative;
	width: 210px;
}

.PhoneMessageLeft {
	margin-right: 2rem;
}

.PhoneMessageRight {
	margin-left: 2rem;
}

.Media {
	max-width: 2rem;
	max-height: 2rem;
	border-radius: 50%;
	display: block;
	transition: all 0.2s ease-in-out;
	position: absolute;
}

.PhoneMessageMediaLeft {
	top: -1rem;
	left: -1rem;
}

.PhoneMessageMediaRight {
	top: -0.75rem;
	right: -0.75rem;
}

.Content {
	width: 100%;
}
